import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';

const ExploreSlider = loadable(() =>
  import('../components/pageModules/modules/exploreSlider'),
);
const PageBanner = loadable(() =>
  import('../components/pageModules/modules/pageBanner'),
);

const ContestTemplate = ({ data: { page } }) => {
  const { seo, config, pageModules } = page || {};

  return (
    <Layout config={config}>
      <PageMeta {...seo} />
      <PageBanner {...page?.contestBanner} bannerImages={page?.bannerImages} />
      {pageModules && <ModuleZone {...pageModules} />}
      <ExploreSlider className='pt-space-m pb-space-m' />
    </Layout>
  );
};

export default ContestTemplate;

export const contestQuery = graphql`
  query standardContestQuery($slug: String!) {
    page: sanityContestPortal(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      config: pageConfig {
        reverseHeader
        unpinnedHeader
      }
      pageModules: modules {
        ...ContestModules
      }
      title
      contestBanner {
        image {
          ...ImageWithPreview
        }
        text: _rawText(resolveReferences: { maxDepth: 10 })
      }
      bannerImages {
        accentColor {
          value
        }
        leftImage {
          ...ImageWithPreview
        }
        layout
        rightImage {
          ...ImageWithPreview
        }
      }
    }
  }
`;
